import { Component } from 'react'
import ScrollReveal from "./ScrollReveal";

export default class Items extends Component {
    render() {
        const output = []
        
        this.props.dates.forEach(date => {
            output.push(
                <div className="timeline-item" key={date}>
                    <ScrollReveal date={date} />
                </div>
            )
        })
        
        return Object.values(output)
    }
}