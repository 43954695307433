import { Component } from 'react';

export default class Footer extends Component {
  render() {
    return (
      <div className="mt-3 card-footer text-muted text-center">
        Made with ❤️ by Raashi, Ansh, Kara & Astro
      </div>
    )
  }
}
