import { Component } from 'react'
import { getWishes } from '../firebase'
import Header from 'components/Header'
import Wish from 'components/Wish'
import "../wishes.css"

export default class WishBoard extends Component {

  constructor(props) {
    super(props)

    this.state = { 
      wishes: []
    }
  }
  
  async componentDidMount() {
    const wishes = await getWishes()
    this.setState({ wishes })
  }
  
  render() {
    const wishList = (col) => {
      return this.state.wishes.map((wish, index) => {
        if (index % 3 === col) {
          return <Wish {...wish} />
        }
        else return <></>
      }) 
    }

    return (
      <>
        <Header subHeading={false} addWishButton={true} heading='Wish Board'/>
        <div className="container-fluid main-container">
          <div className="board brandon-grotesque">
            <div className="row">
              <div className="col-md-12">
                <div className="kudos-container">
                  <div className="col-sm-4"> { wishList(0) } </div>
                  <div className="col-sm-4"> { wishList(1) } </div>
                  <div className="col-sm-4"> { wishList(2) } </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
