import { Switch, Route } from "react-router-dom";
import Home from 'views/Home'
import DatePage from 'views/DatePage'
import WishBoard from 'views/WishBoard'
import WishCreate from 'views/WishCreate'
import Footer from 'components/Footer'

export const Routes = () => (
  <>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/date/:date" component={DatePage} />
      <Route path="/wish/board" component={WishBoard} />
      <Route path="/wish/create" component={WishCreate} />
    </Switch>
    <Footer />
  </>
)