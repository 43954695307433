import { Component } from 'react'
import { addWish, uploadImage } from '../firebase'
import Header from 'components/Header'
import "../wishes.css"
import { Camera } from 'react-bootstrap-icons'; //, EmojiHeartEyes, CameraVideo
import Upload from 'rc-upload';
import TextareaAutosize from 'react-textarea-autosize';
import Wish from '../components/Wish'

const PreviewWish = (props) => {
  return (
    <div className="text-center">
      <div className="col-sm-4"></div>
      <div className="col-sm-4">
        <Wish {...props}/>
      </div>
    </div>
  )
}

export default class WishCreate extends Component {

  constructor(props) {
    super(props)

    this.state = {
      name: '',
      wish: '',
      imageUrl: '',
      errors: {},
      preview: {}
    }
  }
  
  handleWishChange = (event) => {
    this.setState({
      wish: event.target.value
    })
  }

  handleNameChange = (event) => {
    this.setState({
      name: event.target.value
    })
  }

  formValidation = () => {
    let formIsValid = true;
    let errors = {}

    let { name, wish } = this.state
    if (!name) {
      formIsValid = false
      errors["name"] = " Let us know who you are "
    }

    if (!wish) {
      formIsValid = false
      errors["wish"] = " Don't forget to add your wish :) "
    }

    if ( name !== "" ) {
      if ( !name.match(/^[a-zA-Z\s]+$/) ) {
        formIsValid = false;
        errors["name"] = " Unfortunately only letter names are allowed ";
      }
    }

    this.setState({ errors })
    return formIsValid
  }

  handleSubmit = (event) => {
    event.preventDefault();

    if( !this.formValidation() ) {
      return
    }

    addWish({
      name: this.state.name,
      wish: this.state.wish,
      imageUrl: this.state.imageUrl
    });

    this.props.history.push('/wish/board')   
  }

  isFileImage = (file) => file && file['type'].split('/')[0] === 'image';
  
  render() {
    const props = {
      onProgress: async (step, file) => {

        if ( !this.isFileImage(file) ) {
          const errors = {
            image: "File is not an accepted image type"
          }
          this.setState({ errors })
          return
        }

        const loading = 'loading'
        if (this.state.imageUrl !== loading) {
          this.setState({ imageUrl: loading })
        }
        if (step.percent === 100) {
          const imageUrl = await uploadImage(file)
          this.setState({imageUrl})
        }
      }
    };

    return (
      <>
        <Header subHeading={false} heading='Add a Wish' />
        <div className="container main-container text-center">
          <div className="row">
            <div className="col-md-12 col-md-offset-2">
              <form className="padding-bottom-sm" onSubmit={this.handleSubmit}>
                <div className="kudo-builder">
                  <div className="form-group">
                    <Upload {...props}>
                      <button title="Add Picture" type="button" className="btn kudo-btn header-button">
                        <Camera size={17}/> Add Image
                      </button>
                    </Upload>
                    <div style={{color: "red"}}>{this.state.errors["image"]}</div>
                    {/* <button title="Add Picture" type="button" className="btn kudo-btn header-button">
                      <Camera size={17}/> Add Image <input type="file" />
                    </button> */}
                    {/* <button title="Add Gif" type="button" className="btn kudo-btn header-button">
                    <EmojiHeartEyes size={17}/> Add Gif
                    </button>
                    <button type="button" title="Add Video" className="btn kudo-btn header-button">
                     <CameraVideo size={17} /> Add Video
                    </button> */}
                  </div>
                  <div className="form-group">
                  Preview your wish below as you make updates
                  <PreviewWish 
                    name={this.state.name}
                    imageUrl={this.state.imageUrl}
                    wish={this.state.wish}
                    />
                  </div>
                  <div className="form-group">
                    <input type="hidden" name="wish"/>
                    <div>
                      <div className="kb-editable editing">
                        <span className="kb-editable-edit-textarea">
                          <div className="textarea-container">
                            <TextareaAutosize 
                              placeholder="Let us know what you have to say..."
                              autoFocus value={this.state.wish}
                              onChange={this.handleWishChange}
                              role="textbox"
                              aria-label="wish"
                              className="content-editable medium-editor-element"
                              spellCheck="true"
                              data-medium-editor-element="true"
                              aria-multiline="true"
                              data-medium-editor-editor-index="1"
                              data-medium-focused="true"/>
                          </div>
                        </span>
                      </div>
                      <span style={{color: "red"}}>{this.state.errors["wish"]}</span>
                    </div>
                  </div>
                  <div className="form-group">
                    <input type="text" value={this.state.name} name="name" onChange={this.handleNameChange} placeholder="Enter name here..." />
                    <div style={{color: "red"}}>{this.state.errors["name"]}</div>
                  </div>
                  <div className="margin-bottom-sm">
                    <span><button type="submit" className="btn btn-primary"> Post </button></span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    )
  }
}
