import { Component } from 'react'
import Items from '../components/Items'
import { getDates } from '../firebase'
import Header from '../components/Header'

export default class Home extends Component {

  constructor(props) {
    super(props)
    this.state = {
      dates: []
    }
  }

  async componentDidMount () {
    const dates = await getDates()
    this.setState({ dates })
  }

  render() {
    return (
      <>
        <Header subHeading={true} />
        <section className="timeline">
          <div className="container">
            <Items dates={this.state.dates}/>
          </div>
        </section>
      </>
    );
  }
}
