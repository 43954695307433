import firebase from "firebase/app"
import "firebase/storage"
import 'firebase/database'
import "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyByoE031e0L5dZMOGpY3xhWd1-pWYdnI3c",
    authDomain: "ra-timeline.firebaseapp.com",
    databaseURL: "https://ra-timeline-default-rtdb.firebaseio.com",
    projectId: "ra-timeline",
    storageBucket: "ra-timeline.appspot.com",
    messagingSenderId: "50882121043",
    appId: "1:50882121043:web:09b3552af5f8f7810c65a6",
    measurementId: "G-QE658KDZ85"
};

// Initialize
export const init = () => {
    if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
        firebase.analytics()
    } else {
        firebase.app();
    }
}

export const getImages = async (date) => {
    init()

    const storage = firebase.storage();
    const storageRef = storage.ref();

    const getUrl = itemRef => storageRef.child(itemRef.fullPath).getDownloadURL();

    const getFolders = async folderRef => {
      const files = await folderRef.listAll()

      const imageObj = {}
      await Promise.all(files.items.map(async itemRef => {
        const url = await getUrl(itemRef)
        if (itemRef.name.startsWith('Original')) {
          imageObj['fullscreen'] = url
          imageObj['originalAlt'] = `${folderRef.name} original image`
        } else if (itemRef.name.startsWith('Thumbnail')) {
          imageObj['original'] = url
        }
      }))

      return imageObj
    }

    const getList = async (ref) => {
      const res = await ref.listAll()

      return Promise.all(res.prefixes.map(folderRef => getFolders(folderRef)))
    }

    // Get all the images
    const imagesRef = storageRef.child(`images/${date}/`);

    return getList(imagesRef)
}

export const getContent = async (date) => {
    init()

    const storage = firebase.storage();
    const storageRef = storage.ref();

    // Get the content
    const contentUrl = await storageRef.child(`content/${date}.md`).getDownloadURL()

    return new Promise(resolve => {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", contentUrl, true);
      xhr.onload = () => {
        resolve(xhr.response);
      };
      xhr.onerror = () => {
        resolve(undefined);
        console.error("** An error occurred during the XMLHttpRequest");
      };
      xhr.send();
    })
}

export const getTitle = async (date) => {
    init()

    const dbRef = firebase.database().ref(`dates/${date}/`);

    const snapshot = await dbRef.once('value');
    const { title } = snapshot.val();
    return title;
}

export const getDates = async () => {
    init()

    const dbRef = firebase.database().ref('dates')
    const snapshot = await dbRef.once('value')
    if (snapshot.exists()) {
        const items = snapshot.val()
        const sortedDates = Object.keys(items).sort((a, b) => Date.parse(a) - Date.parse(b))
        return sortedDates
    }
}

export const getWishes = async () => {
    init()

    const dbRef = firebase.database().ref('wishes')
    const snapshot = await dbRef.once('value')
    if(snapshot.exists()) {
        // Transform object into an array before returning
        const val = snapshot.val()
        return Object.values(val)
    }
}

export const uploadImage = async (file) => {
  // Create the file metadata
  const metadata = {
    contentType: 'image/jpeg'
  };

  init()

  const storage = firebase.storage();
  const storageRef = storage.ref();

  // Upload file and metadata to the object 'images/mountains.jpg'
  const uploadTask = storageRef.child(`wishes/` + file.uid).put(file, metadata);

  return new Promise((resolve, reject) => {
    uploadTask.on('state_changed', 
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log('Upload is paused');
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log('Upload is running');
            break;
          default:
        }
      },
      (error) => {
        reject(error)
      },
      () => {
        const imageUrl = uploadTask.snapshot.ref.getDownloadURL();
        resolve(imageUrl)
      }
    )
  })
}

export const addWish = ({name, wish, imageUrl}) => {
    init()

    const wishListRef = firebase.database().ref('wishes')
    const newPostRef = wishListRef.push()
    newPostRef.set({
        name,
        wish,
        time: Date.now(),
        imageUrl
    });
}