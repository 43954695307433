import { Component } from 'react'
// import { Plus } from 'react-bootstrap-icons'
// import { Link } from 'react-router-dom'
export default class WishButton extends Component {

  constructor(props) {
    super(props)

    this.state = {
      redirectTo: '',
      buttonLabel: '',
    }
  }

  componentDidMount () {
    const addWishLabel = ' Add your Wish'
    const seeWishBoardLabel = 'Wishboard'
    this.setState({
      redirectTo: this.props.addWishButton ? '/wish/create' : '/wish/board',
      buttonLabel: this.props.addWishButton ? addWishLabel : seeWishBoardLabel
    })
  }

  render() {
    return <></>;
    // Disable Wishboard option
    // return (
    //   <Link to={this.state.redirectTo}>
    //     <div className="button-wrapper">
    //       <span>
    //         <div className="floating-button clickable">
    //           {this.props.addWishButton && <Plus />} {this.state.buttonLabel}
    //         </div>
    //       </span>
    //     </div>
    //   </Link>
    // );
  }
}
