import ImageGallery from 'react-image-gallery';
import { Component } from 'react'

export default class ImageScroll extends Component {

  constructor () {
    super()
    this.state = {
      fullScreen: false
    }
  }

  onClickFullscreen = () => {
    if (!this.state.fullScreen) {
      this._imageGallery.fullScreen()
    } else {
      this._imageGallery.exitFullScreen()
    }

    this.setState({fullScreen: !this.state.fullScreen})
  }

  render() {
    return (
      <ImageGallery
        items={this.props.images}
        showThumbnails={false}
        showPlayButton={false}
        showFullscreenButton={this.props.showFullscreenButton}
        ref={i => this._imageGallery = i}
        onClick={this.onClickFullscreen}
      />
    )
  }
}