import { Component } from 'react';
import logo from 'img/RA-Transparent-White.png'
import { Link } from 'react-router-dom'
import WishButton from './WishButton'
export default class Header extends Component {
  
  render() {
    return (
      <header>
        <div className="container text-center">
          <Link to="/">
            <img className="logo" src={logo} width="150" height="130" alt="R/A Logo" />
          </Link>
        </div>
        <div className="header-intro text-center">
          <h1 className="display-1">{this.props.heading || 'Our Timeline'}</h1>
          {this.props.subHeading &&
            <h4>
              Scroll through time to read our story.
            </h4>
          }
        </div>
        <WishButton addWishButton={this.props.addWishButton}/>
      </header>
    );
  }
}
