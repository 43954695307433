import { Component } from 'react'
import HeartBeat from '../gif/HeartBeat.gif'

export default class Wish extends Component {
  render() {
    const imageUrl = this.props.imageUrl === 'loading' ? HeartBeat : this.props.imageUrl

    return (
      <div className="kudo">
        <div>
          <div className="mask-container">
            <div className="placeholder">
              <img src={imageUrl} alt="" className="kudo-image img-responsive" />
            </div>
          </div>
          <div className="kudo-body has-media">
            <div className="message">
              <div className="view-only">
                <p>{this.props.wish}</p>
              </div>
            </div>
            <div className="kudo-footer">
              <div className="flex flex-space-between flex-end">
                <div className="ml-auto">
                  <div className="byline">
                    <span>{this.props.name}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
