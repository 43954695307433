import { Component } from 'react'
import Markdown from 'markdown-to-jsx';

export default class FadeScroll extends Component {

    constructor(props) {
        super(props)

        this.state = {
            showFade: false,
            height: this.props.expand ? 'auto' : 0
        }
    }

    handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) { 
            this.setState({showFade: false})
        } else if (!this.state.showFade) {
            this.setState({showFade: true})
        }
    }

    componentDidMount () {
        this.divElement.addEventListener('scroll', this.handleScroll, {passive: false});
        this.setState({showFade: (this.divElement.clientHeight > 200) ? true : false})
    }

    componentWillUnmount () {
        this.divElement.removeEventListener('scroll', this.handleScroll)
    }

    componentDidUpdate (prevProps, prevState) {
        // check if expand has toggled
        const height = this.divElement?.clientHeight;
        let showFade = !this.state.showFade && height >= 200

        if (prevState.showFade !== this.state.showFade) {
            showFade = this.state.showFade
        }
            
        if (prevProps.expand !== this.props.expand) {
            this.setState({ 
                height: this.state.height === 0 ? 'auto' : 0,
                showFade
            })
        } else if (showFade) {
            this.setState({ showFade })
        }
    }
  
    render () {
        return (
            <>
                <div
                    className="fadedScroller"
                    ref={ (divElement) => { this.divElement = divElement } }
                >
                    <Markdown parseroptions={{ commonmark: true }}>{this.props.content}</Markdown>
                </div>
                <p 
                    className="fadedScroller_fade" 
                    style={{
                        display: this.state.showFade ? 'block' : 'none'
                    }}
                >
                </p>
            </>
      );
    }
  }