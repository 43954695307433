import { Component } from 'react'
import Markdown from 'markdown-to-jsx'
import { getContent, getImages, getTitle, getDates } from '../firebase'
import { Link } from 'react-router-dom'
import Header from 'components/Header'
import ImageScroll from 'components/ImageScroll';
import Loader from "react-loader-spinner";
import YoutubeEmbed from 'components/YoutubeEmbed'

export default class DatePage extends Component {

  constructor (props) {
    super(props)

    this.state = {
      dates: [],
      date: '',
      images: [],
      title: '',
      content: '',
      showFullscreenButton: false,
      previousButtonDisplay: false,
      nextButtonDisplay: false,
      nextDate: '',
      previousDate: '',
      contentColumnClass: ''
    }
  }

  setButtonDisplay (dates, date) {
    if (dates[dates.length - 1] === date) {
      return { previousButtonDisplay: true, nextButtonDisplay: false }
    } else if (dates[0] === date) {
      return { previousButtonDisplay: false, nextButtonDisplay: true }
    } 
    return { previousButtonDisplay: true, nextButtonDisplay: true }
  }

  setButtonDates (dates, date) {
    return { 
      previousDate: dates[dates.indexOf(date) - 1], 
      nextDate: dates[dates.indexOf(date) + 1] 
    }
  }

  async loadData (date) {
    // Reset component for updated page
    if (this.state.content) {
      this.setState({content: ''})
    }

    const title = await getTitle(date)
    const content = await getContent(date)
    const images = await getImages(date) || []
    const dates = await getDates()
    const { previousButtonDisplay, nextButtonDisplay } = this.setButtonDisplay(dates, date)
    const { previousDate, nextDate } = this.setButtonDates(dates, date)

    const showFullscreenButton = (images.length > 0)

    this.setState({ dates, title, content, images, nextButtonDisplay, previousButtonDisplay, nextDate, previousDate, showFullscreenButton, date,
      contentColumnClass: ( images.length > 0 ) ? 'col-lg-8' : ''
    })
  }

  async componentDidMount () {
    const { date } = this.props.match.params
    this.loadData(date)
  }

  async componentDidUpdate () {
    if (this.props.match.params.date === this.state.nextDate) {
      this.loadData(this.state.nextDate)
    } else if (this.props.match.params.date === this.state.previousDate) {
      this.loadData(this.state.previousDate)
    }
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <>
        <Header subHeading={false}/> 
        <div className="container mt-5 text-center">
          {this.state.content ?
            <>
              <div className="row mb-1">
                <div className={this.state.contentColumnClass}>
                    <article>
                      <div>
                        <h1>{this.state.title}</h1>
                        <div>{this.state.date}</div>
                      </div>

                      <section>
                        <Markdown parseroptions={{ commonmark: true }}>{this.state.content}</Markdown>
                        {/* Show video link only for a specific date as below */}
                        { (this.state.date === "10 Jul 2021") && 
                        <>
                          <br></br>
                          <h4>Watch from Kara's Perspective
                            <YoutubeEmbed embedId="QA8TAygG2og" /> 
                          </h4>
                        </>
                        }
                      </section>
                    </article>
                </div>
                
                <div className="col-lg-4">
                  <ImageScroll 
                    images={this.state.images}
                    showFullscreenButton={this.state.showFullscreenButton}
                  />
                </div>
              </div>
              <div className="w-100">
                {this.state.previousButtonDisplay && 
                  <Link to={`/date/${this.state.previousDate}`}>
                    <button className="btn btn-dark btn-lg btn-block page-nav-btn" type="button">
                      &laquo;
                    </button>
                  </Link>
                }
                {this.state.nextButtonDisplay && 
                  <Link to={`/date/${this.state.nextDate}`}>
                    <button className="btn btn-dark btn-lg btn-block page-nav-btn next-page-btn" type="button">
                      &raquo;
                    </button>
                  </Link>
                }
              </div>
              <Link to="/">
                <button className="w-100 btn btn-dark btn-lg btn-block mt-2" type="button">
                  Back to the Full Story
                </button>
              </Link>
            </>
          : <Loader type="Hearts" color="#48090E" height={300} width={300} />
          }
        </div>
      </>
    );
  }
}
