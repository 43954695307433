import React, { Component } from "react";
import { getContent, getImages, getTitle } from '../firebase'
import ImageScroll from './ImageScroll';
import "react-image-gallery/styles/css/image-gallery.css";
import FadeScroll from "./FadeScroll";
import AnimateHeight from 'react-animate-height';
import { ChevronExpand, ChevronContract } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom'
import Loader from "react-loader-spinner";

export default class ScrollReveal extends Component {
  constructor(props) {
    super(props);
    
    const expand = false

    this.state = {
      images: [],
      expand,
      height: expand ? 'auto' : 0,
      content: '',
      showFullscreenButton: false,
      fullScreen: false,
      title: ''
    }
  }

  async loadData () {
    const content = await getContent(this.props.date)
    const images = await getImages(this.props.date) || []

    if (images.length > 0) {
      this.setState({ images, content, showFullscreenButton: true });
    } else if (content !== '') {
      this.setState({ content })
    }
  }

  async componentDidMount() {
    const title = await getTitle(this.props.date)
    this.setState({ title })

    if(this.state.expand) {
      await this.loadData()
    }
  }

  async componentDidUpdate () {
    if(this.state.expand && this.state.content === '') {
      await this.loadData()
    }
  }

  toggleHeight = () => {
    // Toggle expand/collapse
    this.setState({
      expand: !this.state.expand,
      height: this.state.height === 0 ? 'auto' : 0
    })
  }

  render () {
    return (
      <>
        { this.state.expand 
          ? <button type="button" className="btn btn-outline-primary timeline-img" onClick={this.toggleHeight} aria-label="Button Contract">
            <ChevronContract size={30}/>
          </button>
          :
          <button type="button" className="btn btn-outline-primary timeline-img" onClick={this.toggleHeight} aria-label="Button Expand">
            <ChevronExpand size={30}/>
          </button>
        }

        <div className={`timeline-content`}>
          { this.state.expand && !this.state.content
            ? <Loader className="text-center" type="Hearts" color="#48090E" height={200} width={320} />
            : 
            <>
              <div className="date" onClick={this.toggleHeight}>{this.props.date}</div>
              <Link to={`/date/${this.props.date}`} className="timeline-header">{this.state.title}</Link>
              <div>
                <AnimateHeight
                  duration={500}
                  height={this.state.height}
                >
                  <ImageScroll 
                    images={this.state.images}
                    showFullscreenButton={this.state.showFullscreenButton}
                  />  
                  <FadeScroll
                    content={this.state.content}
                    expand={this.state.expand}
                  />
                </AnimateHeight>
              </div>
            </>
          }
        </div>
      </>
    );
  }
};
